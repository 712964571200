import React from 'react';
import {Helmet} from "react-helmet";
import Box from "../../components/box/Box";

export default function Login() {

    return (
        <Box rwFreeHeight={true} disableHeader={true} loaded={true} leftBoxClassName={'w-100'}
             height={'300px'}>
            <Helmet
                title={' ورود به بهابین'}
            />
            <div className="text-center p-5">
                <p>کاربر گرامی برای استفاده از خدمات بهابین فعلا به ورود و احراز هویت نیازی نیست...</p>
            </div>
        </Box>
    )
}